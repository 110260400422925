
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
  html {
    scroll-behavior: smooth;
  }
  
body {

margin: 0;
padding: 0;
box-sizing: border-box;
font-family: "poppins", sans-serif;
overflow-x: hidden;
}

ul {
    list-style: none;

}

a {
    text-decoration: none;
    cursor: pointer;
}
