.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1e1e1e;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .loader {
    text-align: center;
    color: white;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.1);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    margin: 0 auto 20px;
  }

  .loader-text {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cool-typo {
    font-size: 48px;
    margin-bottom: 20px;
    color: white;
    letter-spacing: 2px;
    animation: pulse 1s infinite alternate; /* Add a subtle pulsing effect */
  }

  .loader-text p {
    font-size: 24px;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
