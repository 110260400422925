.main-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.image-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-preview-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-preview-img {
    max-width: 70%;
    max-height: 70%;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.hero-section {
    text-align: center;
    margin-bottom: 20px;
}

.hero-section img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.description-section,
.screenshots-section,
.technologies-section {
    margin-top: 20px;
}

.screenshots-section img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
}

.work-infos {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    h2 {
        font-size: 50px;
        font-weight: 300;
        margin-bottom: 10px;
    }

    p {
        font-size: 24px;
        font-weight: 200;
        margin-bottom: 20px;
    }
}