.name {
  position:absolute;
top: 50%;
 
  bottom: 0;
  text-align: center;
  width: 100%;
}

.mine{
position: relative;
top: 0;
 
  text-align: center;
 
    font-size: 250px;
    color: white;
    z-index: 1;
   bottom: 0;
 
    white-space: nowrap;
    font-weight: 400;
}